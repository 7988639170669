body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #000;
  font-size:1.25em;
  
}


h1 {
  font-size: 4rem;
  font-family: rockwell, sans-serif;
  font-style: normal;
  font-weight: 400;
}


.col-sm-3 {
  width:25%;
  float: left;
  height:250px;
  margin-bottom:6px;
}

.col-sm-6
{
  width:50%;
  float:left;
  padding-left:15px;
  padding-right:15px;
  position: relative;
}

.col-sm-4
{
  width:33%;
  float:left;
  height: 140px;
  margin-bottom: 6px;
}

.col-sm-12
{
  width:100%
}

.col-md-6
{
  float:left;
}


.carousel-caption H3 {
  font-family: rockwell, sans-serif;
}

#about img {
  max-width:300px;
  width:100%;
  margin:24px;
  -webkit-box-shadow: 12px 12px 12px 0px rgba(0,0,0,0.4);
  -moz-box-shadow: 12px 12px 12px 0px rgba(0,0,0,0.4);
  box-shadow: 12px 12px 12px 0px rgba(0,0,0,0.4);
}

.right {
  float:right;
}

.left {
  float: left;
}


.App {
  text-align: center;
}

#about,  #services, #contact {
  background-color:#fff;
  width:100%;
  padding-top:12px;
}

#gallery {
  padding-top:12px;
}

#topMenu {
  top:0;
  position: sticky;
  display:block;
  z-index: 100;
}

#mobileMenu {
  display:none;
}

#menu {
  background-color: #fff;
}

.menuPanel1
{
  background-color: #fff;
  width:100%;
  text-align: center;
  padding: 6px;
  height:24px;
  border:1px solid #f0f0f0;

}

.menuPanel ul
{
  margin: 0px;
  padding: 0px;
}

.menuPanel button:hover{
  cursor:pointer;
}

.menuPanel button, .mobileMenu button
{
  color:#000;
  font-size:1.1em;
  padding:3px;
  margin: 6px 24px;
  text-decoration: none;
  background-color: transparent;
  border:none;
}

.menuPanel ul button:focus, .mobileMenu ul button:focus{
  outline:none;
}

.menuPanel .is-current
{
  text-decoration: underline;
  font-weight: bold;
  
}


.txtPanel
{
  width:100%;
  max-width: 990px;
  margin:0px auto;
  padding:30px 0px;
  z-index:10;
}

.txtPanel p {
  text-align:left;
  padding:6px 24px;
}



.txtPanel h2 {
  font-family: 'EB Garamond', serif;
  font-weight: bold;
  font-size: 1.5em;
  margin:12px;
  text-align: left;
}


#about, #gallery
{
  background-color:rgb(230, 230, 230);
  width:100%;
}





.gtPanel {
background-color:rgb(230, 230, 230);
border: 1px #d1d1d1 solid;
height: 450px;
margin-bottom: 12px;
}




#services {
  padding-top:12px;
  background-image: url('/img/i18.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

#services .txtPanel {
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 1.25em;
  font-weight: 600;
}

#services .txtPanel p {
  text-align: center;

}

#services .txtPanel ul {
  list-style: none;
}

.galleryList img
{
  max-width:100%
}

.galleryList
{
  width: 100%;
  max-width: 990px;
  margin:0px auto;
  padding: 30px 0px;
  z-index:10;
}

.galleryItems {
  overflow: hidden;
}

.__react_modal_image__icon_menu {
  display:none !important;
}

.__react_modal_image__medium_img {
  max-width: 80% !important;
  max-height: 80% !important;
}

.Contact {
  max-width: 990px;
  margin:0px auto;
  padding:18px 0px;
  z-index:10;

  overflow: hidden;
}

.inputText {
  text-align: left;
  padding: 6px;
  margin: 6px 0px;
  height: 50px;
  overflow: hidden;
}


.inputMsg {
  text-align: left;
  padding: 6px;
  margin: 6px 0px;
  overflow: hidden;
}

.inputText label, .inputMsg label
{
  font-size:1em;
  font-weight: 500;
  margin: 6px;
}

.inputText .inputBox
{
  width:95%;
  margin: 6px 0px;
  padding: 3px;

}

textarea
{
  width: 95%;
  margin: 6px 0px;
  height: 100px;
  padding: 6px;

}


.sendMsg button
{
  padding:6px;
  margin:6px;
}


#footer{
  height:50px;
  color:#fff;
  font-size: 0.9em;
}

#footer a{ 
  color:#fff;
  margin:12px;
  padding:6px;
  font-size: 0.9em;
}



@media only screen and (max-width: 768px) {


    .carousel .slide .legend
    {
      font-size:1em !important;
      bottom:0px !important;
      padding:1px !important;
    }

    #about img {
      max-width: none;
      box-shadow: none;
      margin:0px;
    }

  .col-sm-6, .col-sm-4
  {
    width:100%;
    overflow: hidden;
  }

  .blankpanel {
    display: none;
  }

  #topMenu {
    display:none;
  }
  


  #mobileMenu {
    top:0;
    position: sticky;
    display:block;
    float: right;
    margin: 12px;
    text-align: right;
    z-index: 10;
  }

  .mobileMenu
  {
    width:100%;
    background-color: white;
    text-align: center;
    font-size:1.2em;
    border: 1px solid #333;


  }

  .mobileMenu button {
    width:100%;
    margin:6px 0px;
    padding:6px;

  }
  #hamBurger {
    width:50px;
    height:50px;
  }

  #mobilePanel {
    z-index: 20;
  }

  .col-sm-3 {
    width:100%;
  }

  #memorials .txtPanel {
    padding: 6px 12px 6px 6px;
  }

}


@media (min-width:991px) and (max-width:1200px){
  .App-header {
    min-height: 85vh;
  }
}

@media (min-width:769px) and (max-width:990px){
.App-header {
  min-height: 62vh;
}
}

@media (min-width:661px) and (max-width:768px){
.App-header {
  min-height: 50vh;
}
}

@media (max-width:660px){
.App-header {
  min-height: auto;
  margin: 0px;
}
header p {
  font-size:18px;
  margin:0px;
  padding:12px;
}

header h1 {
  margin:0px; 
}
}