.App {
  text-align: center;
}

h1 {
  font-family: 'EB Garamond', serif;
  font-weight: 100;
  font-size: 4rem;
}



.App-header {
  
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  width:100%;
  max-width:990px;
  margin:0px auto;
}

.App-header h1 {
  background-color: rgba(17,17,17,0.5);
}

.App-header p {
  background-color: rgba(17,17,17,0.5);
}



.App-link {
  color: #61dafb;
}

.headerText
{
  color:#fff;
  font-size: calc(14px + 2vmin);
  width:100%;
  max-width:990px;
}

#header {
  background-image: url('./images/WLBanner.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}




@media (min-width:991px) and (max-width:1200px){
    .App-header {
      min-height: 85vh;
    }
}

@media (min-width:769px) and (max-width:990px){
  .App-header {
    min-height: 62vh;
  }
}

@media (min-width:661px) and (max-width:768px){
  .App-header {
    min-height: 50vh;
  }
}

@media (max-width:660px){
  .App-header {
    min-height: auto;
    margin: 0px;
  }
  header p {
    font-size:18px;
    margin:0px;
    padding:12px;
  }

  header h1 {
    margin:0px; 
  }
}